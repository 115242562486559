import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { lessons } from '../../data';

const Lessons = () => {
  const title = 'Lesson videos';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Youtube</th>
            <th>土豆</th>
          </tr>
        </thead>

        <tbody>
          {lessons.map((lesson, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <a href={lesson.youtube}>Youtube</a>
              </td>
              <td>
                <a href={lesson.tudou}>土豆</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default Lessons;
